import gql from 'graphql-tag'

const GET_INSTRUCTORS = gql`
    query allInstructors($locale: SiteLocale!){
        allInstructors(locale:$locale){
            id
            name
            email
            learnMoreLink
            bio
            isLeadership
            profileImage{
                alt
                blurhash
                responsiveImage{
                    alt
                    src
                }
            }
        }
    }
`

export default GET_INSTRUCTORS
