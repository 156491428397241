import styled from 'styled-components'

export const Container = styled.div`
opacity: ${props => (props.show ? '1' : '0')};
visibility: ${props => (props.show ? 'visible' : 'hidden')};
transition: opacity 0.25s;
position: fixed; /* Fixed/sticky position */
top: 45vh; /* Place the button at the bottom of the page */
right: 20px; /* Place the button 30px from the right */
z-index: 99; /* Make sure it does not overlap */
cursor: pointer;
display: flex;
flex-direction: column;
transition: 0.25s all;
&:hover {
    opacity: 0.8;
  }
`

export const Button = styled.img`
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.15));
  margin-bottom: 5px;
`

export const ScrollText = styled.span`
  font-family: ${props => props.theme.fonts.quintary};
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.fonts.primary};
`
