import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Home from '../containers/Home'
import Login from '../containers/Login'
import MyAccount from '../containers/MyAccount'
import Students from '../containers/Students'
import Courses from '../containers/Courses'
import Videos from '../containers/VideoPlayer'
import OurTeam from '../containers/OurTeam'
import CalendarScreen from '../containers/Calendar'
import { ROUTE_PATHS } from '../utils/constants'

export const routes = {
  home: {
    path: ROUTE_PATHS.HOME,
    routePath: () => ROUTE_PATHS.HOME,
    routeComponent: PublicRoute,
    component: Home,
    exact: true,
  },
  login: {
    path: ROUTE_PATHS.LOGIN,
    routePath: () => ROUTE_PATHS.LOGIN,
    routeComponent: PublicRoute,
    component: Login,
    exact: true,
  },
  team: {
    path: ROUTE_PATHS.TEAM,
    routeComponent: PublicRoute,
    component: OurTeam,
    exact: true,
  },
  myAccount: {
    path: ROUTE_PATHS.ACCOUNT,
    routeComponent: PrivateRoute,
    component: MyAccount,
    exact: true,
  },
  students: {
    path: ROUTE_PATHS.STUDENTS,
    routeComponent: PrivateRoute,
    component: Students,
    exact: true,
  },
  courses: {
    path: ROUTE_PATHS.COURSES,
    routeComponent: PrivateRoute,
    component: Courses,
    exact: true,
  },
  videos: {
    path: ROUTE_PATHS.VIDEOS,
    routeComponent: PrivateRoute,
    component: Videos,
  },
  calendar: {
    path: ROUTE_PATHS.CALENDAR,
    routeComponent: PrivateRoute,
    component: CalendarScreen,
    exact: true,
  },
}

export const renderRoutes = Object.entries(routes)
