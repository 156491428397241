import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import SelectRow from './Components/SelectRow'
import TableHeader from './Components/TableHead'
import {
  StyledTable, StyledFooter,
} from './styles'
import SelectedBar from './Components/SelectedBar'

const tableHeaders = [
  { label: 'Action', bold: true },
  { label: 'Name', bold: true },
  { label: 'Age', bold: false },
  { label: 'Time Zone', bold: false },
  { label: 'Email', bold: false },
  { label: 'Course', bold: false },
  { label: 'Workshop', bold: false }]

export const LoadingTable = () => (
  <TableContainer>
    <SelectedBar numSelected={false} />
    <StyledTable>
      <TableHeader tableHeaders={tableHeaders} />
    </StyledTable>
  </TableContainer>
)

const TTable = ({
  data, page, setPage, resultsPerPage, setResultsPerPage,
  totalPages, selectedData, setSelectedData, setSort, setOrder, order, refetch,
}) => {
  const updateSelect = (id, add) => {
    if (add) {
      setSelectedData([...selectedData, { id, page }])
    } else {
      setSelectedData(selectedData.filter(el => !id.includes(el.id)))
    }
  }

  const handleChangeRowsPerPage = event => {
    setResultsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  return (
    <TableContainer>
      <SelectedBar
        numSelected={selectedData.length}
        selectedStudents={selectedData}
        updateSelect={updateSelect}
        setPage={setPage}
        refetch={refetch}
        page={page}
      />
      <StyledTable>
        <TableHeader
          tableHeaders={tableHeaders}
          refetch={refetch}
          setSort={setSort}
          setOrder={setOrder}
          order={order}
        />
        <TableBody>
          {data.map(el => (
            <SelectRow
              key={el.id}
              student={el}
              selectState={!!selectedData.find(e => e.id === el.id)}
              updateState={updateSelect}
              page={page}
              resultsPerPage={resultsPerPage}
            />
          ))}
        </TableBody>
        <StyledFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              page={page}
              count={data.length}
              rowsPerPage={resultsPerPage}
              labelDisplayedRows={() => `${page + 1} of ${totalPages} `}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              nextIconButtonProps={{ disabled: ((page + 1) === totalPages) }}
              backIconButtonProps={{ disabled: page === 0 }}
            />
          </TableRow>
        </StyledFooter>
      </StyledTable>
    </TableContainer>
  )
}

export default TTable
