import gql from 'graphql-tag'

export const VIEWER = gql`
  query instructorViewer {
    instructorViewer {
      id
      email
      firstName
      lastName
      password
      DOB
      gender
    }
  }
`
