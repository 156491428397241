import React, { useState } from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { InputStyle, Container, Label } from './styles'

const InputEditable = ({
  value, setValue, label, error, password, color, icon = true, ...rest
}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Container {...rest}>
      <Label>{label}</Label>
      <InputStyle
        id={password ? 'standard-password-input' : 'standard-basic'}
        type={password ? 'password' : 'text'}
        value={value}
        onChange={e => setValue(e.target.value)}
        error={error}
        color={color || 'primary'}
        disabled={!editing}
        InputProps={{
          endAdornment: icon
            ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setEditing(!editing)}
                  onMouseDown={event => event.preventDefault()}
                >
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ) : <></>,
        }}
        {...rest}
      />
    </Container>

  )
}

export default InputEditable
