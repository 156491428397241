import React from 'react'
import { styled as styledM } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

export const InputStyle = styledM(({ ...rest }) => <TextField {...rest} />)({
  margin: '0',
  width: props => (props.width || '160px'),
  height: props => (props.height || '70px'),
  fontFamily: 'Gilroy',
  fontWeight: 'normal',
  background: 'none',
  position: 'relative',
})
