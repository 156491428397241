export const ROUTE_PATHS = {
  LOGIN: '/login',
  HOME: '/',
  ACCOUNT: '/account',
  STUDENTS: '/students',
  COURSES: '/courses',
  TEAM: '/team',
  CALENDAR: '/calendar',
  VIDEOS: '/videos',
}
