import gql from 'graphql-tag'


export const GET_COURSE = gql`
  query course($id: ItemId!, $locale:SiteLocale!){
  course(filter:{ id:{eq:$id}}, locale:$locale){
    id
    instructor{
      id
      email
      name
    }
    about
    title
    workshop
    lessons{
      id
    }
    zoomLink
    zoomPassword
    syllabus {
      url
    }
  }
}
`

export const GET_LESSONS = gql`
  query lessonsByCourse($id: ItemId!, $locale:SiteLocale!) {
  allLessons(filter: { course: { eq: $id } }, orderBy: title_ASC, locale:$locale) {
    id
    title
    video
    content {
      ... on HeaderRecord {
        text
      }
      ... on BodyRecord {
        text
      }
      ... on ImageRecord {
        media {
          url 
          alt
        }
      }
      ... on AssetRecord {
        media {
          url
        }
      }
    }
  }
}

`
