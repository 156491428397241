import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 57px;

  #title {
    margin-bottom: 40px;
  }
`

export const GrayContainer = styled.div`
  background: #EFEFEF;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

`

export const TableContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 1045px;
    margin: auto;
    
    align-items: center;
    justify-content: center;
    background: white;
`
export const LoadingContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 1045px;
    height: 700px;
    margin: auto;
    
    align-items: center;
    justify-content: center;
`
export const ErrorText = styled.p`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: ${({ theme }) => theme.colors.fonts.primary};
`
