import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import Input from '../../../../components/Input'
import InputSelectSmall from '../../../../components/InputSelectSmall'
import ErrorMessage from '../../../../components/ErrorMessage'
import SuccessMessage from '../../../../components/SuccessMessage'

import { EditStudentSchema } from '../../../../utils/yup'
import useForm from '../../../../utils/useForm'

import { Container, FormRow } from './styles'
import { EDIT_STUDENT, GET_COURSES } from './graphql'
import GET_PAGINATED_STUDENTS from '../../graphql'

const ageOptions = (['10', '11', '12', '13', '14', '15', '16', '17', '18']).map(age => ({
  label: age,
  value: age,
}))

const offsets = [-12, -11, -10, -9.5, -9, -8, -7,
  -6, -5, -4, -3.5, -3, -2, -1, 0, 1, 2, 3, 3.5, 4,
  4.5, 5, 5.5, 5.75, 6, 6.5, 7, 8, 8.75, 9, 9.5, 10,
  10.5, 11, 12, 12.75, 13, 14]

const timeOptions = offsets.map(offs => {
  const intOff = offs * 100
  let label = 'UTC'
  if (intOff > 0) {
    label += '+'
  } else if (intOff === 0) {
    label += '±'
  }
  if (intOff % 100 === 0) {
    return { value: offs, label: `${label}${offs}:00` }
  }
  if (intOff % 50 === 0) {
    return { value: offs, label: `${label}${Math.trunc(offs)}:30` }
  }

  return { value: offs, label: `${label}${Math.trunc(offs)}:45` }
})

const EditStudent = ({
  modalOpen, setModalOpen, firstName = '', lastName = '',
  age = '', timeZone = '0', email = '',
  course = '', workshop = '', id, page, resultsPerPage,
}) => {
  const {
    state: studentInfo, dispatch: setStudentInfo, validate, errors, reset,
  } = useForm({
    firstName,
    lastName,
    age,
    email,
    course,
    workshop,
    timeZone,
    password: '',
  }, EditStudentSchema)

  const { data, loading: coursesLoading } = useQuery(GET_COURSES)

  const updatedVariables = {
    id,
    password: studentInfo.password,
    ...(firstName !== studentInfo.firstName && { firstName: studentInfo.firstName }),
    ...(lastName !== studentInfo.lastName && { lastName: studentInfo.lastName }),
    ...(age !== studentInfo.age && { age: parseInt(studentInfo.age, 10) }),
    ...(timeZone !== studentInfo.timeZone && { timeZone: studentInfo.timeZone }),
    ...(email !== studentInfo.email && { email: studentInfo.email }),
    ...(course !== studentInfo.course && { course: studentInfo.course }),
    ...(workshop !== studentInfo.workshop && { workshop: studentInfo.workshop }),
  }

  const [editStudent, { loading }] = useMutation(EDIT_STUDENT, {
    variables: updatedVariables,
    onError: () => ErrorMessage.open(),
    onCompleted: () => {
      reset()
      SuccessMessage.open()
    },
    refetchQueries: [{
      query: GET_PAGINATED_STUDENTS,
      variables: { pageNumber: page, pageSize: resultsPerPage },
    }],
  })

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleSubmit = async () => {
    if (await validate()) {
      await editStudent()
      setModalOpen(false)
    }
  }

  return (
    <Container>
      <ErrorMessage text="Error editing student information. Please try again." />
      <SuccessMessage text="Successfully updated student information." />
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle style={{ marginTop: '10px' }}>Edit Student</DialogTitle>
        <DialogContent>
          <FormRow>
            <Input
              value={studentInfo.firstName}
              setValue={val => setStudentInfo({ action: 'firstName', payload: val })}
              label="First Name"
              error={errors.firstName}
              width="225px"
            />
            <Input
              value={studentInfo.lastName}
              setValue={val => setStudentInfo({ action: 'lastName', payload: val })}
              label="Last Name"
              error={errors.lastName}
              width="225px"
            />
            <InputSelectSmall
              value={studentInfo.age}
              setValue={val => setStudentInfo({ action: 'age', payload: val })}
              label="Age"
              options={ageOptions}
              error={errors.age}
              width="120px"
            />
            <InputSelectSmall
              value={studentInfo.timeZone}
              setValue={val => setStudentInfo({ action: 'timeZone', payload: val })}
              label="Timezone"
              options={timeOptions}
              error={errors.timeZone}
              width="200px"
            />
          </FormRow>
          <FormRow>
            <Input
              value={studentInfo.email}
              setValue={val => setStudentInfo({ action: 'email', payload: val })}
              label="Email"
              width="512px"
              error={errors.email}
            />
          </FormRow>
          <FormRow>
            <Input
              value={studentInfo.password}
              setValue={val => setStudentInfo({ action: 'password', payload: val })}
              label="Password"
              width="512px"
              error={errors.password}
              type="password"
            />
          </FormRow>
          <FormRow>
            {
              coursesLoading
                ? (
                  <InputSelectSmall
                    label="Course"
                    value="loading"
                    options={[{ value: 'loading', label: 'Loading courses...' }]}
                    width="512px"
                  />
                )
                : (
                  <InputSelectSmall
                    label="Course"
                    value={studentInfo.course}
                    setValue={val => setStudentInfo({ action: 'course', payload: val })}
                    options={
                      data.getAllCourses.filter(c => c.type === 'COURSE')
                        .map(c => ({ value: c.courseName, label: c.courseName }))
                    }
                    showError
                    error={errors.course}
                    width="512px"
                  />
                )
            }
          </FormRow>
          <FormRow>
            {
            coursesLoading
              ? (
                <InputSelectSmall
                  label="Workshop"
                  value="loading"
                  options={[{ value: 'loading', label: 'Loading courses...' }]}
                  width="512px"
                />
              )
              : (
                <InputSelectSmall
                  label="Workshop"
                  value={studentInfo.workshop}
                  setValue={val => setStudentInfo({ action: 'workshop', payload: val })}
                  options={
                    data.getAllCourses.filter(c => c.type === 'WORKSHOP')
                      .map(c => ({ value: c.courseName, label: c.courseName }))
                  }
                  showError
                  error={errors.workshop}
                  width="512px"
                />
              )
            }
          </FormRow>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            style={{ fontWeight: 'bold', fontFamily: 'Nunito', margin: '15px 10px' }}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleSubmit}
            color="secondary"
            style={{ fontWeight: 'bold', fontFamily: 'Nunito', margin: '15px 10px' }}
            loading={loading}
          >
            {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : 'CONFIRM'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default EditStudent
