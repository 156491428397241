import gql from 'graphql-tag'

const GET_PAGINATED_STUDENTS = gql`
    query getPaginatedStudents($pageNumber: Int!, $pageSize:Int!, $sort: String!, $order: String!){
        getPaginatedStudents(pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, order: $order){
            students{
            id
            firstName
            lastName
            age
            timeZone
            email
            course{
                courseName
            }
            workshop{
                courseName
            }
            }
            total
            totalPages
        }
    }
`
export default GET_PAGINATED_STUDENTS
