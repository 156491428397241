import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

export const StyledCheckbox = styled(Checkbox)`
    
    &.MuiCheckbox-root{
        color: ${({ theme }) => theme.colors.background.quarternary};
    }
    &.Mui-checked{
        color: ${({ theme }) => theme.colors.peacock};
    }

`

export const FloatingRow = styled(TableRow)`
    box-shadow: 0px 4px 20px ${({ theme }) => theme.colors.accents.shadow};
    border: none;
    height: 48px;
`
export const StyledCell = styled(TableCell)`
   height: 48px;
   padding-top: 0px;
   padding-bottom: 0px;
   width: ${props => props.width || 'auto'}
`

export const EditCell = styled.div`
    width: 100px;
    /* margin-left: -80px; */
    /* margin-right: 30px; */
    height: 48px;
    padding: 6px;
    opacity: 0;

    >* {
        text-transform: none;
        color: ${({ theme }) => theme.colors.peacock};
        font-weight: bold;
        font-size: 14px;
    }

    ${FloatingRow}:hover & {
        opacity: 1;
    }
`

export const BoldText = styled.p`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.fonts.secondary};
`

export const Text = styled.p`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 14px;
    line-height: 19px;
    text-overflow: ellipsis;
    max-width: 205px;
    overflow: hidden;
    :hover{
        overflow: visible;
        max-width: 300px;
    }
    color: ${({ theme }) => theme.colors.fonts.secondary};
`
