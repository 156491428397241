import React from 'react'
import SIJMorning from '../../assets/images/SIJMorning.png'
import SIJAfternoon from '../../assets/images/SIJNoon.png'
import SIJEvening from '../../assets/images/SIJNight.png'
import { BackgroundDiv } from './styles'


const Background = ({ children, time }) => {
  const evaluate = () => {
    if (time === 'm') {
      return SIJMorning
    }
    if (time === 'a') {
      return SIJAfternoon
    }
    return SIJEvening
  }

  return (
    <BackgroundDiv
      image={evaluate()}
    >
      {children}
    </BackgroundDiv>
  )
}

export default Background
