import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import {
  Container, TitleSection, TabPanelContainer, InfoHeader, ResourceContainer,
} from './styles'

const TabPanel = props => {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TabPanelContainer p={3}>
          {children}
        </TabPanelContainer>
      )}
    </div>
  )
}

const CourseInfoMenu = ({
  currentVideoName, resources, instructorName, about, syllabusUrl, zoomLink, zoomPassword, tab,
}) => {
  const [currentTab, setCurrentTab] = useState(tab)
  return (
    <Container>
      <TitleSection>
        <h1>{currentVideoName}</h1>
      </TitleSection>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => setCurrentTab(newValue)}
        style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
        centered
      >
        <Tab label="RESOURCES" style={{ fontWeight: 'bold' }} />
        <Tab label="ABOUT" style={{ fontWeight: 'bold' }} />
        <Tab label="ZOOM LINK" style={{ fontWeight: 'bold' }} />
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <ResourceContainer>
          {resources}
        </ResourceContainer>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        {!!instructorName && (
        <InfoHeader>
          This class is taught by
          {' '}
          {instructorName}
        </InfoHeader>
        )}
        <ResourceContainer>
          {about}
        </ResourceContainer>
        {syllabusUrl ? (
          <InfoHeader>
            Syllabus:
            {' '}
            <a href={syllabusUrl} target="_blank" rel="noopener noreferrer">Course Syllabus</a>
          </InfoHeader>
        ) : <></>}
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        The zoom link is
        {' '}
        {zoomLink}
        .
        {' '}
        <br />
        <br />
        The password is
        {' '}
        {zoomPassword}
      </TabPanel>
    </Container>
  )
}

export default CourseInfoMenu
