import gql from 'graphql-tag'

export const INSTRUCTOR_VIEWER = gql`
  query InstructorViewer{
    instructorViewer {
      id
      firstName
      lastName
      email
      DOB
      gender
      email
    }
  }
`
