import React, { useEffect, useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useLazyQuery } from '@apollo/react-hooks'
import MoonLoader from 'react-spinners/MoonLoader'

import HeaderText from '../../components/HeaderText'
import Table, { LoadingTable } from './components/StudentTable'
import AddStudent from './components/AddStudent'
import {
  Container, GrayContainer, TableContainer, LoadingContainer, ErrorText,
} from './styles'
import GET_PAGINATED_STUDENTS from './graphql'


const Students = () => {
  const theme = useContext(ThemeContext)
  const [sort, setSort] = useState('')
  const [order, setOrder] = useState('asc')
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const [selectedData, setSelectedData] = useState([])

  const [fetch, {
    data, error, refetch,
  }] = useLazyQuery(GET_PAGINATED_STUDENTS, {
    variables: {
      pageNumber: page, pageSize: resultsPerPage, sort, order,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false)
    },
  })
  useEffect(() => {
    fetch()
  }, [])
  if (error) {
    return (
      <TableContainer>
        <Table loading />
        <LoadingContainer>
          <ErrorText>Could not retrieve data</ErrorText>
        </LoadingContainer>
      </TableContainer>

    )
  }

  return (
    <Container>
      <HeaderText
        id="title"
        text="Students"
        squiggleColor="yellow"
      />
      <AddStudent page={page} resultsPerPage={resultsPerPage} refetch={refetch} setPage={setPage} />
      <GrayContainer>
        <TableContainer>
          {loading ? <LoadingTable />
            : (
              <div>
                <Table
                  data={data.getPaginatedStudents.students}
                  page={page}
                  setPage={setPage}
                  resultsPerPage={resultsPerPage}
                  setResultsPerPage={setResultsPerPage}
                  totalPages={data.getPaginatedStudents.totalPages}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setSort={setSort}
                  setOrder={setOrder}
                  order={order}
                  refetch={refetch}
                />
              </div>
            )}
          {loading
            ? (
              <LoadingContainer>
                <MoonLoader loading color={theme.colors.fonts.primary} styles={{ padding: '100px' }} />
              </LoadingContainer>
            )
            : <></>}

        </TableContainer>
      </GrayContainer>
    </Container>
  )
}

export default Students
