import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'

import { InputStyle } from './styles'

const InputSelectSmall = ({
  value, setValue, label, options, color, error, showError, ...rest
}) => (
  <InputStyle
    select
    value={value}
    label={label}
    color={color || 'secondary'}
    onChange={e => setValue(e.target.value)}
    error={error}
    {...rest}
  >
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </InputStyle>

)

export default InputSelectSmall
