export const EMAIL_REQUIRED = 'Email address is required'
export const EMAIL_VALID = 'Valid email is required'
export const PASSWORD_REQUIRED = 'Password is required'
export const PASSWORD_LENGTH = 'Password with minimum 6 characters is required'
export const FIRST_NAME_REQUIRED = 'First name is required'
export const LAST_NAME_REQUIRED = 'Last name is required'
export const ADDRESS_REQUIRED = 'Address is required'
export const CITY_NAME_REQUIRED = 'City is required'
export const STATE_NAME_REQUIRED = 'State is required'
export const ZIP_CODE_REQUIRED = 'Zip code is required'
export const PHONE_NUMBER_VALID = 'Valid phone number format is required'
export const CONFIRM_EMAIL_REQUIRED = 'Confirm email is required'
export const CONFIRM_EMAIL_MATCH = 'Email and confirm email must match'
export const CONFIRM_PASSWORD_REQUIRED = 'Confirm password is required'
export const CONFIRM_PASSWORD_MATCH = 'Password and confirm password must match'
export const GENERIC_REQUIRED = 'This is a required field'
export const FULL_NAME_REQUIRED = 'First and last name required'
