import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import MoonLoader from 'react-spinners/MoonLoader'
import Background from '../../components/Background'
import HeaderText from '../../components/HeaderText'
import {
  Greeting, Container, ParentContainer, ErrorContainer, TopContainer, LoadingContainer,
} from './styles'
import theme from '../../theme'
import { GET_COURSES } from './graphql'
import CourseWidget from '../../components/CourseWidget'
import Button from '../../components/PrimaryButton'
import { useGlobalContext } from '../../utils/globalContext'

const time = () => {
  const hour = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Tokyo', hour: 'numeric', hour12: false })
  if (hour >= 0 && hour < 12) {
    return {
      time: 'm', color1: theme.colors.purple, color2: theme.colors.marine, squiggleColor: theme.colors.marigold, text: 'Good morning,',
    }
  }
  if (hour >= 12 && hour < 18) {
    return {
      time: 'a', color1: theme.colors.purple, color2: theme.colors.peacock, squiggleColor: theme.colors.teal, text: 'Good afternoon,',
    }
  }
  return {
    time: 'n', color1: theme.colors.teal, color2: theme.colors.background.primary, squiggleColor: theme.colors.teal, text: 'Good evening,',
  }
}

const Videos = () => {
  const inputs = time()
  const isNight = inputs.time === 'n'
  const { viewer, language } = useGlobalContext()
  const { data, loading, error } = useQuery(GET_COURSES, {
    variables: { locale: language },
  })
  if (loading) {
    return (
      <Background time={inputs.time}>
        <div style={{ height: '100%', position: 'relative' }}>
          <Greeting night={isNight} />
          <TopContainer>
            <HeaderText
              videos
              text={inputs.text}
              text2={viewer && viewer.firstName}
              color1={inputs.color1}
              color2={inputs.color2}
              squiggleColor={inputs.squiggleColor}
            />
            <Button margin="20px 0">
              EDIT COURSE CONTENT
            </Button>
          </TopContainer>
          <LoadingContainer>
            <MoonLoader loading color={isNight ? 'white' : theme.colors.purple} />
          </LoadingContainer>
        </div>
      </Background>
    )
  }
  if (error) {
    return (
      <Background time={inputs.time}>
        <div style={{ height: '100%', position: 'relative' }}>
          <Greeting night={isNight} />
          <TopContainer>
            <HeaderText
              videos
              text={inputs.text}
              text2={viewer && viewer.firstName}
              color1={inputs.color1}
              color2={inputs.color2}
              squiggleColor={inputs.squiggleColor}
            />
            <Button>
              EDIT COURSE CONTENT
            </Button>
          </TopContainer>
          <ErrorContainer>
            An error has occured. Please try again.
          </ErrorContainer>
        </div>
      </Background>
    )
  }
  return (
    <Background time={inputs.time}>
      <div style={{ height: '100%', position: 'relative' }}>
        <Greeting night={isNight} />
        <TopContainer>
          <HeaderText
            videos
            text={inputs.text}
            text2={viewer && viewer.firstName}
            color1={inputs.color1}
            color2={inputs.color2}
            squiggleColor={inputs.squiggleColor}
          />
          <Button margin="20px 0" onClick={() => window.open('https://sij.admin.datocms.com')}>
            EDIT COURSE CONTENT
          </Button>
        </TopContainer>
        <ParentContainer>
          <Container>
            {data.allCourses.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: 'true' }), 1, -1)
              .map(d => (
                <CourseWidget
                  {...d}
                />
              ))}
          </Container>
        </ParentContainer>
      </div>
    </Background>

  )
}
export default Videos
