import React from 'react'
import {
  ProfileContainer, CoulmnContainer, NoSaveButton,
} from './styles'
import EditInput from '../../../../components/InputEditable'


const InstructorVitalsEditor = ({
  firstName, lastName, birthday, gender,
}) => (
  <ProfileContainer>
    <CoulmnContainer>
      <EditInput
        label="First Name"
        value={firstName}
        icon={false}
      />
      <EditInput
        label="Last Name"
        value={lastName}
        icon={false}
      />
      <EditInput
        label="Birthday"
        value={birthday}
        icon={false}
      />
      <EditInput
        label="Gender"
        value={gender}
        icon={false}
      />
    </CoulmnContainer>
    <NoSaveButton />
  </ProfileContainer>
)

export default InstructorVitalsEditor
