/* eslint-disable no-mixed-operators */
import React from 'react'
import ReactPlayer from 'react-player'

import play from '../../../../assets/images/Play.svg'
import { Container, PlayButton } from './styles'

const VideoDisplayer = ({ url, playing }) => (
  <Container>
    <ReactPlayer
      url={url}
      controls
      width="800px"
      height={`${800 * 720 / 1280}px`}
      playing={playing}
      playIcon={<PlayButton src={play} />}
    />
  </Container>
)


export default VideoDisplayer
