import styled from 'styled-components'

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background.secondary};
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const SubHeader = styled.h3`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.fonts.secondary};
    margin-bottom: 10px;
    margin-top: 10px;
`

export const Description = styled.p`
  font-family: 'Gilroy';
  font-weight: 600;
  color: ${({ theme }) => theme.colors.fonts.secondary};
  margin: 10px;
`

export const HeaderBox = styled.div`
    display: flex;
    height: 250px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.primary};
    align-items: center;
    justify-content: center;
`

export const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 782px;
    height: 400px;
    border-radius: 2px;   
    margin-top: 20px; 
`
