/* eslint-disable no-unused-vars */
import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import MoonLoader from 'react-spinners/MoonLoader'
import { INSTRUCTOR_VIEWER } from './graphql'
import InstructorProfileEditor from './components/InstructorProfileEditor'
import InstructorVitalsEditor from './components/InstructorVitalsEditor'
import {
  PageContainer, SubHeader, Description, HeaderBox, EditorContainer,
} from './styles'
import HeaderText from '../../components/HeaderText'
import theme from '../../theme'

const MyAccount = () => {
  const { data, loading } = useQuery(INSTRUCTOR_VIEWER, {
    onCompleted: () => {
    },
  })
  return (
    <PageContainer>
      <HeaderBox>
        <HeaderText text="My Account" squiggleColor="blue" color1={theme.colors.fonts.primary} />
      </HeaderBox>
      {loading
        ? (
          <div style={{ height: '50vh', paddingTop: '10vh' }}>
            <MoonLoader loading color={theme.colors.fonts.primary} />
            {' '}
          </div>
        ) : (
          <div>
            <EditorContainer>
              <SubHeader>Account</SubHeader>
              <Description>Change private information.</Description>
              <InstructorProfileEditor
                emailAddress={data.instructorViewer.email}
              />
            </EditorContainer>
            <EditorContainer>
              <SubHeader>Vitals</SubHeader>
              <InstructorVitalsEditor
                firstName={data.instructorViewer.firstName}
                lastName={data.instructorViewer.lastName}
                birthday={data.instructorViewer.DOB}
                gender={data.instructorViewer.gender}
              />
            </EditorContainer>
          </div>
        )}
    </PageContainer>
  )
}

export default MyAccount
