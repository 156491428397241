import React from 'react'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { grey } from '@material-ui/core/colors'

import {
  Container, VStack, TitleSection, CourseName, CurrentVideo, Video, VideoTitle,
} from './styles'

const VideoSelector = ({
  courseName, lessons, setCurrentLesson, currentLesson,
}) => (
  <Container>
    <TitleSection>
      <CourseName>{courseName}</CourseName>
      <CurrentVideo>
        <ChevronRightIcon style={{ color: grey[400] }} />
        <h3>{!lessons ? 'No lessons yet' : currentLesson.title}</h3>
      </CurrentVideo>
    </TitleSection>
    <VStack>
      {lessons && (lessons.map(l => (
        <Video onClick={() => setCurrentLesson(l)} current={currentLesson.id === l.id}>
          <VideoTitle>
            <PlayCircleFilledIcon color="secondary" />
            <p>{l.title}</p>
          </VideoTitle>
          {!!(l.video && l.video.video) && <p><span>{`${Math.floor(l.video.video.duration / 60)}:${(`0${l.video.video.duration % 60}`).slice(-2)}`}</span></p>}
        </Video>
      )))}
    </VStack>
  </Container>
)

export default VideoSelector
