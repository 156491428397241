import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'

import SuccessMessage from '../../../../../../components/SuccessMessage'

import { StyledToolBar, SelectedText } from './styles'
import { DELETE_STUDENTS } from './graphql'

const SelectedBar = ({
  numSelected, selectedStudents, updateSelect, setPage, refetch, page,
}) => {
  const [deleteStudent, { loading }] = useMutation(DELETE_STUDENTS, {
    variables: {
      ids: numSelected ? selectedStudents.map(student => student.id) : [],
    },
    onCompleted: () => {
      SuccessMessage.open()
      updateSelect(selectedStudents.map(student => student.id), false)
      if (page === 0) {
        refetch()
      } else setPage(0)
    },
  })

  return (numSelected ? (
    <>
      <SuccessMessage text="Successfully deleted selected students." />
      <StyledToolBar numSelected={numSelected}>
        <SelectedText>{`${numSelected} Selected`}</SelectedText>
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={deleteStudent}>
            {loading
              ? <CircularProgress style={{ width: '20px', height: '20px' }} />
              : <DeleteIcon />}
          </IconButton>
        </Tooltip>
      </StyledToolBar>
    </>
  ) : (
    <StyledToolBar numSelected={numSelected} />
  ))
}

export default SelectedBar
