export const formatTimezone = offset => {
  const intOff = offset * 100
  let label = 'UTC'
  if (intOff > 0) {
    label += '+'
  } else if (intOff === 0) {
    label += '±'
  }
  if (intOff % 100 === 0) {
    return `${label}${offset}:00`
  }
  if (intOff % 50 === 0) {
    return `${label}${Math.trunc(offset)}:30`
  }

  return `${label}${Math.trunc(offset)}:45`
}
