import React, { useState } from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import { Document, Page, pdfjs } from 'react-pdf'
import {
  PdfPaper, PageControls, PageNumbers, PageButton, LoadingContainer,
} from './styles'
import 'react-pdf/dist/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


const PdfViewer = ({ file }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  return (
    <PdfPaper elevation={3}>
      <Document
        file={file}
        onLoadSuccess={pdf => setTotalPages(pdf.numPages)}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        renderMode="svg"
      >
        <Page
          pageNumber={currentPage}
          className="annotationLayer"
          height={792}
          renderInteractiveForms
          renderMode="svg"
          loading={<LoadingContainer><MoonLoader height="700" /></LoadingContainer>}
        />
        <PageControls elevation={2} variant="contained">
          <PageButton
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            ‹
          </PageButton>
          <PageNumbers disabled>
            {`${currentPage} of ${totalPages}`}
          </PageNumbers>

          <PageButton
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            ›
          </PageButton>
        </PageControls>
      </Document>
    </PdfPaper>
  )
}

export default PdfViewer
