import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  ProfileContainer, CoulmnContainer, SaveButton,
} from './styles'
import useForm from '../../../../utils/useForm'
import { editInstructor } from '../../../../utils/yup'
import EditInput from '../../../../components/InputEditable'
import EDIT_INSTRUCTOR from './graphql'
import PrimaryButton from '../../../../components/PrimaryButton'

const InstructorProfileEditor = ({ emailAddress }) => {
  const {
    state, dispatch, validate, errors, reset,
  } = useForm({ password: '', email: emailAddress }, editInstructor)
  const isChanged = (emailAddress === state.email && state.password === '')


  const [
    changeInstructor, { loading },
  ] = useMutation(EDIT_INSTRUCTOR, {
    variables:
    {
      input: {
        password: state.password,
        email: state.email,
      },
    },
    onCompleted: () => reset(),
  })


  const handleEdit = async () => {
    if (await validate()) {
      changeInstructor()
    }
  }

  return (
    <ProfileContainer>
      <CoulmnContainer>
        <EditInput
          label="Password"
          value={state.password}
          setValue={e => dispatch({ action: 'password', payload: e })}
          password
          placeholder="******"
          error={!!errors.password}
          helperText={errors.password}
        />
        <EditInput
          label="Email"
          value={state.email}
          setValue={e => dispatch({ action: 'email', payload: e })}
          error={!!errors.email}
          helperText={errors.email}
        />
      </CoulmnContainer>
      <SaveButton>
        <PrimaryButton
          color="primary"
          onClick={handleEdit}
          disabled={isChanged}
          loading={loading}
          width="114px"
          height="36px"
        >
          Save

        </PrimaryButton>
      </SaveButton>
    </ProfileContainer>
  )
}

export default InstructorProfileEditor
