/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react'

import { Button } from '@material-ui/core'
import {
  StyledCheckbox, FloatingRow, BoldText, Text, StyledCell, EditCell,
} from './styles'
import EditStudent from '../../../EditStudent'
import { formatTimezone } from '../../../../../../utils/utility'

const SelectRow = ({
  student, selectState, updateState, ...rest
}) => {
  const [editOpen, setEditOpen] = useState(false)

  const formattedTomezone = formatTimezone(student.timeZone)

  return (
    <FloatingRow hover>
      <StyledCell>
        <StyledCheckbox
          color="defualt"
          checked={selectState}
          onChange={event => updateState(student.id, event.target.checked)}
        />
      </StyledCell>
      <StyledCell><BoldText>{`${student.firstName} ${student.lastName}`}</BoldText></StyledCell>
      <StyledCell><Text>{student.age}</Text></StyledCell>
      <StyledCell width="125px"><Text>{formattedTomezone}</Text></StyledCell>
      <StyledCell><Text>{student.email}</Text></StyledCell>
      <StyledCell width="175px"><Text>{student.course.courseName}</Text></StyledCell>
      <StyledCell width="175px"><Text>{student.workshop.courseName}</Text></StyledCell>
      <EditCell
        onClick={() => {
          setEditOpen(true)
        }}
      >
        <Button>Edit</Button>
      </EditCell>
      <EditStudent
        modalOpen={editOpen}
        setModalOpen={setEditOpen}
        id={student.id}
        firstName={student.firstName}
        lastName={student.lastName}
        age={`${student.age}`}
        timeZone={student.timeZone}
        email={student.email}
        course={student.course.courseName}
        workshop={student.workshop.courseName}
        {...rest}
      />
    </FloatingRow>
  )
}
export default SelectRow
