/* eslint-disable import/extensions */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTE_PATHS } from '../../utils/constants'

import { Container, Logo } from './styles'
import logo from '../../assets/images/LogoPurple.svg'
import PrimaryButton from '../PrimaryButton'
// import LanguageSelect from '../LanguageSelect'
import { materialTheme } from '../../materialTheme'

const UnauthenticatedNavbar = () => {
  const history = useHistory()
  return (
    <Container id="top">
      <PrimaryButton
        width="114px"
        height="35px"
        onClick={() => window.open('https://summerinjapan.com/')}
        style={{ marginRight: 'auto', marginLeft: '40px', ...materialTheme.palette.purpleBackground }}
      >
        Sign Up
      </PrimaryButton>
      <Logo src={logo} onClick={() => history.push('/')} />
      <PrimaryButton
        width="114px"
        height="35px"
        color="primary"
        variant="text"
        onClick={() => history.push(ROUTE_PATHS.TEAM)}
        style={{ margin: '10px' }}
      >
        Our Team
      </PrimaryButton>
      <PrimaryButton
        width="114px"
        height="35px"
        margin="0 40px 0 0"
        onClick={() => history.push(ROUTE_PATHS.LOGIN)}
      >
        Log In
      </PrimaryButton>
      {/* <LanguageSelect dark /> */}
    </Container>
  )
}
export default UnauthenticatedNavbar
