import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -23px;
`

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 522px;
  margin: 15px 0;

  >* {
    margin: 0 10px;
  }
`
