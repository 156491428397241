import gql from 'graphql-tag'

const EDIT_INSTRUCTOR = gql`
    mutation editInstructor($input: EditInstructorInput!){
        editInstructor(input: $input){
            id
            email
            password
        }
    }
`
export default EDIT_INSTRUCTOR
