/* eslint-disable no-unused-vars */
import * as yup from 'yup'
import {
  EMAIL_REQUIRED,
  EMAIL_VALID,
  PASSWORD_REQUIRED,
  PASSWORD_LENGTH,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  GENERIC_REQUIRED,
  FULL_NAME_REQUIRED,
} from './errorMessages'

const email = yup
  .string()
  .trim()
  .required(EMAIL_REQUIRED)
  .email(EMAIL_VALID)

const password = yup
  .string()
  .required(PASSWORD_REQUIRED)
  .min(6, PASSWORD_LENGTH)

const firstName = yup
  .string()
  .trim()
  .required(FIRST_NAME_REQUIRED)

const lastName = yup
  .string()
  .trim()
  .required(LAST_NAME_REQUIRED)

const nulledString = yup
  .string()
  .transform(v => (v === '' ? null : v))

export const joinForm = yup.object().shape({
  email,
  about: yup.string().trim().required(GENERIC_REQUIRED),
  interest: yup.string().trim().required(GENERIC_REQUIRED),
})

export const editInstructor = yup.object().shape({
  email: yup.string().trim().email(EMAIL_VALID),
  password: nulledString.trim().min(6, PASSWORD_LENGTH).nullable(),
  timeZone: yup.number(),
})

export const LoginYupSchema = yup.object().shape({
  email,
  password,
})

export const AddStudentYupSchema = yup.object().shape({
  firstName: yup.string().required(GENERIC_REQUIRED).trim(),
  lastName: yup.string().required(GENERIC_REQUIRED).trim(),
  email,
  age: yup.number().required(GENERIC_REQUIRED).positive().integer(),
  password,
  timeZone: yup.string().required(GENERIC_REQUIRED),
  course: yup.string().required(GENERIC_REQUIRED).trim(),
  workshop: yup.string().required(GENERIC_REQUIRED).trim(),
})

export const EditStudentSchema = yup.object().shape({
  firstName: yup.string().required(GENERIC_REQUIRED).trim(),
  lastName: yup.string().required(GENERIC_REQUIRED).trim(),
  email,
  age: yup.number().required(GENERIC_REQUIRED).positive().integer(),
  password: nulledString.trim().min(6, PASSWORD_LENGTH).nullable(),
  timeZone: yup.string().required(GENERIC_REQUIRED),
  course: yup.string().required(GENERIC_REQUIRED).trim(),
  workshop: yup.string().required(GENERIC_REQUIRED).trim(),
})

export const addSub = yup.object().shape({
  email,
})
