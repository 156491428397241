import gql from 'graphql-tag'

export const ADD_STUDENT = gql`
  mutation AddStudent (
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $age: Int!
    $timeZone: Float!
    $course: String!
    $workshop: String!
  )
   {
    addStudent (
      input: {
        firstName: $firstName
        lastName: $lastName 
        email: $email 
        password: $password 
        age: $age 
        timeZone: $timeZone 
        course: $course 
        workshop: $workshop
      }
    ) {
      id
    }
  }
`

export const GET_COURSES = gql`
  query GetCourses {
    getAllCourses {
      id
      type
      courseName
    }
  }
`
