import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import BounceLoader from 'react-spinners/ClipLoader'
import {
  FooterContainer,
  MailList, Links, BigText, SmallText, Bottom, BottomText, BottomDiv, InputContainer,
  Top, SideDiv, NakedLink,
} from './styles'
import Input from '../Input/index'
import PrimaryButton from '../PrimaryButton'
import { ROUTE_PATHS } from '../../utils/constants'
import { ADD_SUBSCRIBER } from './graphql'
import { addSub } from '../../utils/yup'


const Footer = () => {
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [add, { loading }] = useMutation(ADD_SUBSCRIBER, {
    variables: {
      email,
    },
    onCompleted: () => setEmail(''),
    onError: err => {
      setError(err.graphQLErrors[0].message)
    },
  })
  const handleSubmit = async () => {
    const result = await addSub.validate({ email }).catch(err => setError(err.errors[0]))
    if (result) {
      setError('')
      add()
    }
  }

  return (
    <FooterContainer>
      <Top>
        <MailList>
          <BigText>
            Stay Connected &nbsp;
            <span role="img" aria-label="envelope">✉️</span>
          </BigText>
          <InputContainer>
            <Input
              value={email}
              setValue={setEmail}
              error={error}
              width="150px"
              label="Email Address"
            />
            {loading ? (
              <div style={{ marginBottom: '10px', marginLeft: '5px' }}>
                <BounceLoader
                  size={10}
                  color="#254C62"
                />
              </div>
            ) : <></>}
          </InputContainer>
          <PrimaryButton onClick={() => handleSubmit()} width="114px" height="36px">Subscribe</PrimaryButton>
        </MailList>
        <Links>
          <SmallText onClick={() => window.open('https://summerinjapan.com/en/news/')}>
            Latest News
          </SmallText>
          <SmallText to={ROUTE_PATHS.OUR_TEAM}>
            Our Team
          </SmallText>
          <SmallText onClick={() => window.open('https://summerinjapan.com/faq/')}>
            FAQ
          </SmallText>
          <SmallText to={ROUTE_PATHS.LOGIN}>
            Login
          </SmallText>
        </Links>
      </Top>
      <Bottom>
        <SideDiv>
          <BottomText margin="120px">©︎ Summer in JAPAN 2021</BottomText>
          <BottomDiv margin="60px">
            <BottomText>seminar@summerinjapan.com</BottomText>
          </BottomDiv>
        </SideDiv>
        <SideDiv>
          <BottomDiv margin="60px">
            <BottomText> Mailing Address</BottomText>
            <BottomText>
              1-5-16, Suehiro-machi, Oita City, Oita Japan 8700027
            </BottomText>
          </BottomDiv>
          <BottomDiv>
            <NakedLink href="https://summerinjapan.com/en/"><BottomText> summerinjapan.com/en/</BottomText></NakedLink>
          </BottomDiv>
        </SideDiv>
      </Bottom>
    </FooterContainer>
  )
}

export default Footer
