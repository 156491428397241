import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import ToolBar from '@material-ui/core/Toolbar'

export const SelectedText = styled(Typography)`
    &.MuiTypography-root{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        flex: 1 1 100%;
        margin-right: auto;
        color: ${({ theme }) => theme.colors.peacock};
    }
`
export const StyledToolBar = styled(ToolBar)`
    &.MuiToolbar-root{
        display: flex;
        justify-content: space-between;
        width: 1045px;
        
        margin: auto;
        background-color: ${({ theme }) => theme.colors.accents.tealHighlight};
        ${({ numSelected, theme }) => !numSelected && `background-color: ${theme.colors.background.primary}`};
    }
`
