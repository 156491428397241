import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { StyledCell } from './styles'

const TableHeader = ({
  tableHeaders, refetch, setSort, setOrder, order,
}) => (
  <TableHead>
    <TableRow>
      {tableHeaders.map(header => (
        <StyledCell
          bold={header.bold}
          onClick={() => {
            switch (header.label) {
              case 'Name':
                setSort('lastName')
                if (order === 'asc') {
                  setOrder('desc')
                } else {
                  setOrder('asc')
                }
                refetch()
                break
              case 'Course':
                setSort('course')
                if (order === 'asc') {
                  setOrder('desc')
                } else {
                  setOrder('asc')
                }
                refetch()
                break
              case 'Workshop':
                setSort('workshop')
                if (order === 'asc') {
                  setOrder('desc')
                } else {
                  setOrder('asc')
                }
                refetch()
                break
              case 'Email':
                setSort('email')
                if (order === 'asc') {
                  setOrder('desc')
                } else {
                  setOrder('asc')
                }
                refetch()
                break
              case 'Age':
                setSort('age')
                if (order === 'asc') {
                  setOrder('desc')
                } else {
                  setOrder('asc')
                }
                refetch()
                break
              case 'Time Zone':
                setSort('timeZone')
                if (order === 'asc') {
                  setOrder('desc')
                } else {
                  setOrder('asc')
                }
                refetch()
                break
              default:
                break
            }
          }}
        >
          {header.label}
        </StyledCell>
      ))}
    </TableRow>
  </TableHead>
)

export default TableHeader
