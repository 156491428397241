import styled from 'styled-components'
import TableCell from '@material-ui/core/TableCell'

export const StyledCell = styled(TableCell)`
    &.MuiTableCell-head{
        font-family: ${({ theme }) => theme.fonts.secondary};
        font-style: normal;
        ${({ bold }) => (bold && 'font-weight: 800;')} 
        font-size: 14px;
        line-height: 20px;
        :hover{
            cursor: pointer;
        }
        border: none;
        letter-spacing: 0.1px;
        padding-top: 16px;
        padding-bottom: 7px;

        color: ${({ theme }) => theme.colors.fonts.primary};
        transition: 0.25s;
    }
`
