import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import HeaderText from '../../components/HeaderText'
import Input from '../../components/Input'

import { useGlobalContext } from '../../utils/globalContext'
import { ROUTE_PATHS } from '../../utils/constants'
import { Container, InputContainer, LinkContainer } from './styles'
import useForm from '../../utils/useForm'
import { LoginYupSchema } from '../../utils/yup'
import PrimaryButton from '../../components/PrimaryButton'
import { LOGIN_ADMIN } from './graphql'

const Login = () => {
  const {
    state, dispatch, validate, errors,
  } = useForm({
    email: '',
    password: '',
  }, LoginYupSchema)

  const [DBError, setError] = useState('')

  const history = useHistory()

  const { setIsSignedIn } = useGlobalContext()

  const [loginAdmin] = useMutation(
    LOGIN_ADMIN,
    {
      variables: { email: state.email.trim(), password: state.password },
      onCompleted: () => {
        setIsSignedIn(true)
        history.push(ROUTE_PATHS.HOME)
      },
      onError: err => {
        setError(err.graphQLErrors && err.graphQLErrors.length ? err.graphQLErrors[0].message : 'An error occurred')
      },
    },
  )

  const handleSubmit = async () => {
    if (await validate()) {
      loginAdmin()
    }
  }


  return (
    <Container>
      <HeaderText text="Welcome Back" />
      <InputContainer>
        <Input
          value={state.email}
          setValue={val => dispatch({ action: 'email', payload: val })}
          label="Email"
          error={errors.email || DBError}
        />
        <Input
          value={state.password}
          setValue={val => dispatch({ action: 'password', payload: val })}
          label="Password"
          error={errors.password || DBError}
          password
        />
      </InputContainer>
      <LinkContainer>
        <p>Are you a student?</p>
        <a href="https://www.sijonline.com/login"><p><span>Login with a student account</span></p></a>
      </LinkContainer>
      <PrimaryButton onClick={handleSubmit}>CONTINUE</PrimaryButton>
    </Container>
  )
}

export default Login
