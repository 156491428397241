import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 107px;
  position: relative;
`

export const CoverImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 90vh;
  overflow: hidden;
  margin-bottom: 80px;
`

export const CoverImage = styled.img`
  width: 100%;
  min-height: 69vh;
  height:auto;
`

export const CoverTextSection = styled.div`
  position: absolute;
  width: 70%;
  display:flex;
  top: 43%;
  justify-content: center;

  h5 {
    color: ${theme.colors.fonts.againstDark};
    font-family: ${theme.fonts.secondary};
    font-size: 22px;
  }

  a {
   color: ${theme.colors.fonts.againstDark};
  }
`
export const LeftTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 69px;
  flex: 1;
  text-align: end;
`
export const RightTextContainter = styled.div`
  display: flex;
  flex-direction: column;
  
  font-family: ${theme.fonts.secondary};
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color:  ${theme.colors.fonts.againstDark};
  flex: 1;
  margin-top: 13px;

  p{
    margin-bottom: 1em;
  }
`


export const ScrollButtonSection = styled.div`
  position: absolute;
  bottom: 40px;
`

export const Welcome = styled.p`
  color: ${theme.colors.marine};
  font-family: ${theme.fonts.secondary};
  font-size: 22px;
  width: 510px;
  text-align: center;
  margin-bottom: 40px;

  span {
    font-weight: bold;
  }
`
export const ButtonImage = styled.img`
  height: 50px;
  width: 50px;
`

export const HeaderText = styled.h1`
  padding: 0px;
  margin-bottom: 11px;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;

  color: ${theme.colors.fonts.againstDark};
`
