import gql from 'graphql-tag'

export const EDIT_STUDENT = gql`
  mutation EditStudent (
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $age: Int
    $timeZone: Float
    $course: String
    $workshop: String
  ) {   
      editStudentAsInstructor (
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          email: $email
          password: $password
          age: $age
          timeZone: $timeZone
          course: $course
          workshop: $workshop
        }
    ) {
      id
    }
  }  
`

export const GET_COURSES = gql`
  query GetCourses {
    getAllCourses {
      id
      type
      courseName
    }
  }
`
