import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.purple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 82px;
  padding: 0 10px 0 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
`
export const Logo = styled.img`
  width: 47px;
  height: 43px;
  justify-self: left;
  position: absolute;
  left: calc(50% - 23px);
  cursor: pointer;
`

export const HStack = styled.div`
  display: flex;
  align-items: center;
`

export const CalendarIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
`

export const VideosIcon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
`
