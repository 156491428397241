import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import ToolBar from '@material-ui/core/Toolbar'
import Table from '@material-ui/core/Table'
import TableFooter from '@material-ui/core/TableFooter'

export const SelectedText = styled(Typography)`
    &.MuiTypography-root{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        flex: 1 1 100%;
        margin-right: auto;
        color: ${({ theme }) => theme.colors.peacock};
    }
`
export const StyledToolBar = styled(ToolBar)`
    &.MuiToolbar-root{
        display: flex;
        justify-content: space-between;
        width: 1005px;
        
        margin: auto;
        background-color: ${({ theme }) => theme.colors.accents.tealHighlight};
        ${({ numSelected, theme }) => !numSelected && `background-color: ${theme.colors.background.primary}`};
    }
`
export const StyledTable = styled(Table)`
    border-collapse: separate;
    border-spacing: 0 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
`
export const StyledFooter = styled(TableFooter)`
    .MuiTableCell-root{
        border: 0px;
        font-size: 12px;
        line-height: 14px;
        opacity: 0.54;
    }
`
