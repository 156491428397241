import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

export const PageControls = styled(ButtonGroup)`
    opacity: 0;

    height: 7%;
    width: 40%;
    background-color: ${({ theme }) => theme.colors.background.primary};
   
    z-index: 2;

    position: absolute;
    top: 700px;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    
    align-items: stretch;
    transition: 0.25s;
`
export const PdfPaper = styled(Paper)`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 792px;
    flex: 0;
    align-self: center;
    overflow:hidden;
    & :hover ${PageControls} {
        opacity:1;
    }
`

export const PageNumbers = styled.span`
    font-family: ${({ theme }) => theme.fonts.secondary};
    padding-left:5px;
    padding-right: 5px;
    flex:2;
    display:flex;
    align-items: center;
    justify-content: center;
`

export const PageButton = styled(Button)`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.background.primary};
    font-size: 30px;
   &.Mui-disabled{
        background-color: ${({ theme }) => theme.colors.background.primary};
    }
`
export const LoadingContainer = styled.div`
    display: flex;
    height:792px;
    width: 612px;
    justify-content: center;
    align-items: center;
`
