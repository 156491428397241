import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import theme from '../../../../theme'
import Input from '../../../../components/Input'
import InputSelectSmall from '../../../../components/InputSelectSmall'
import ErrorMessage from '../../../../components/ErrorMessage'
import SuccessMessage from '../../../../components/SuccessMessage'

import { AddStudentYupSchema } from '../../../../utils/yup'
import useForm from '../../../../utils/useForm'


import { Container, FormRow } from './styles'
import { ADD_STUDENT, GET_COURSES } from './graphql'

const ageOptions = (['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']).map(age => ({
  label: age,
  value: age,
}))

const offsets = [-12, -11, -10, -9.5, -9, -8, -7,
  -6, -5, -4, -3.5, -3, -2, -1, 0, 1, 2, 3, 3.5, 4,
  4.5, 5, 5.5, 5.75, 6, 6.5, 7, 8, 8.75, 9, 9.5, 10,
  10.5, 11, 12, 12.75, 13, 14]

const timeOptions = offsets.map(offs => {
  const intOff = offs * 100
  let label = 'UTC'
  if (intOff > 0) {
    label += '+'
  } else if (intOff === 0) {
    label += '±'
  }
  if (intOff % 100 === 0) {
    return { value: offs, label: `${label}${offs}:00` }
  }
  if (intOff % 50 === 0) {
    return { value: offs, label: `${label}${Math.trunc(offs)}:30` }
  }

  return { value: offs, label: `${label}${Math.trunc(offs)}:45` }
})

const AddStudent = ({
  refetch, setPage,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const {
    state, dispatch, validate, errors, reset,
  } = useForm({
    firstName: '',
    lastName: '',
    age: '',
    email: '',
    course: '',
    workshop: '',
    timeZone: '0',
    password: '',
  }, AddStudentYupSchema)

  const addVariables = {
    firstName: state.firstName,
    lastName: state.lastName,
    age: parseInt(state.age, 10),
    timeZone: state.timeZone === 'JST' ? -8 : 5,
    email: state.email,
    course: state.course,
    workshop: state.workshop,
    password: state.password,
  }
  const handleRefetch = async () => {
    const { data: { getPaginatedStudents: { totalPages } } } = await refetch()
    setPage(totalPages - 1)
  }

  const [addStudent, { loading }] = useMutation(ADD_STUDENT, {
    variables: addVariables,
    onError: () => {
      ErrorMessage.open()
      reset()
    },
    onCompleted: () => {
      SuccessMessage.open()
      reset()
      handleRefetch()
    },
  })

  const { data, loading: coursesLoading } = useQuery(GET_COURSES)

  const handleClose = () => {
    setModalOpen(false)
    reset()
  }

  const handleSubmit = async () => {
    if (await validate()) {
      await addStudent()
      setModalOpen(false)
    }
  }

  return (
    <Container>
      <ErrorMessage text="Error adding student. Please try again." />
      <SuccessMessage text="Successfully added new student." />
      <IconButton
        onClick={() => setModalOpen(true)}
        style={{
          backgroundColor: theme.colors.peacock,
          color: 'white',
          borderRadius: '50%',
          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14)',
        }}
      >
        <AddIcon />
      </IconButton>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle style={{ marginTop: '10px' }}>Add Student</DialogTitle>
        <DialogContent>
          <FormRow>
            <Input
              value={state.firstName}
              setValue={val => dispatch({ action: 'firstName', payload: val })}
              label="First Name"
              error={errors.firstName}
              width="225px"
            />
            <Input
              value={state.lastName}
              setValue={val => dispatch({ action: 'lastName', payload: val })}
              label="Last Name"
              error={errors.lastName}
              width="225px"
            />
            <InputSelectSmall
              value={state.age}
              setValue={val => dispatch({ action: 'age', payload: val })}
              label="Age"
              options={ageOptions}
              error={errors.age}
              width="120px"
            />
            <InputSelectSmall
              value={state.timeZone}
              setValue={val => dispatch({ action: 'timeZone', payload: val })}
              label="Timezone"
              options={timeOptions}
              error={errors.timeZone}
              width="200px"
            />
          </FormRow>
          <FormRow>
            <Input
              value={state.email}
              setValue={val => dispatch({ action: 'email', payload: val })}
              label="Email"
              width="512px"
              error={errors.email}
            />
          </FormRow>
          <FormRow>
            {
              coursesLoading
                ? (
                  <InputSelectSmall
                    label="Course"
                    value="loading"
                    options={[{ value: 'loading', label: 'Loading courses...' }]}
                    width="512px"
                  />
                )
                : (
                  <InputSelectSmall
                    label="Course"
                    value={state.course}
                    setValue={val => dispatch({ action: 'course', payload: val })}
                    options={
                      data.getAllCourses.filter(course => course.type === 'COURSE')
                        .map(course => ({ value: course.courseName, label: course.courseName }))
                    }
                    showError
                    error={errors.course}
                    width="512px"
                  />
                )
            }
          </FormRow>
          <FormRow>
            {
            coursesLoading
              ? (
                <InputSelectSmall
                  label="Workshop"
                  value="loading"
                  options={[{ value: 'loading', label: 'Loading courses...' }]}
                  width="512px"
                />
              )
              : (
                <InputSelectSmall
                  label="Workshop"
                  value={state.workshop}
                  setValue={val => dispatch({ action: 'workshop', payload: val })}
                  options={
                    data.getAllCourses.filter(course => course.type === 'WORKSHOP')
                      .map(course => ({ value: course.courseName, label: course.courseName }))
                  }
                  showError
                  error={errors.workshop}
                  width="512px"
                />
              )
            }
          </FormRow>
          <FormRow>
            <Input
              value={state.password}
              setValue={val => dispatch({ action: 'password', payload: val })}
              label="Password"
              width="512px"
            />

          </FormRow>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            style={{ fontWeight: 'bold', fontFamily: 'Nunito', margin: '15px 10px' }}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleSubmit}
            color="secondary"
            style={{ fontWeight: 'bold', fontFamily: 'Nunito', margin: '15px 10px' }}
          >
            {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : 'CONFIRM'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default AddStudent
